<template>
  <div v-if="user">
    <v-toolbar class="header">
      <v-btn
        depressed
        color="primary"
        @click="
          $router.push({ name: 'Ichiban', query: { store: '@695yimoc' } })
        "
      >
        <v-icon>mdi-keyboard-return</v-icon>
        一番賞專區
      </v-btn>
      <v-spacer></v-spacer>
      <div class="text-center font-weight-bold mt-0">
        目前點數: {{ user ? user.balance : 0 }}點
      </div>
      <div @click="formDialog = true" class="mx-2">
        <v-badge
          :content="form.items.length || '0'"
          color="primary"
          size="30px"
          overlap
        >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </div>
      <v-menu
        v-model="menu"
        offset-y
        :close-on-click="true"
        :close-on-content-click="false"
        light
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="white"
            class="icon"
            size="28px"
          >
            mdi-menu
          </v-icon>
        </template>
        <v-list>
          <v-list-item @click="navigateTo('History')">
            <v-list-item-title>兌換紀錄</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-row class="pa-6">
      <v-col cols="6">
        <v-select
          label="商品分類"
          outlined
          hide-details
          dense
          v-model="category"
          :items="categoryList"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="搜尋商品名稱"
          v-model.trim="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-alert type="warning" outlined>
          商品均無現貨，預計7-14天出貨，若時間有變動會再通知。
        </v-alert>
      </v-col>
    </v-row>
    <div class="item-wrapper">
      <v-card
        class="item text-center"
        :elevation="4"
        v-for="product of filterProducts"
        :key="product.id"
      >
        <div>
          <v-img
            height="144"
            :src="
              product.圖片
                ? product.圖片[0].thumbnail
                : `https://img.freepik.com/free-icon/image_318-149882.jpg`
            "
          ></v-img>
        </div>
        <div class="pt-1">[{{ product.點數 }}點] {{ product.商品名稱 }}</div>
        <v-spacer></v-spacer>
        <div class="text-center mt-1">
          <v-btn block color="primary" small @click="addToCart(product, true)">
            加入購物車
            <v-icon small class="ml-2">mdi-cart</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="formDialog" width="90%">
      <v-card>
        <v-card-title>購物車</v-card-title>
        <v-card-text>
          <h3>兌換商品明細</h3>
          <v-data-table
            :headers="headers"
            :items="cartSum"
            :mobile-breakpoint="0"
            item-key="index"
            no-data-text="沒有商品"
            hide-default-footer
          >
            <template v-slot:item.image="{ item }">
              <v-img
                :src="item.圖片[0].thumbnail"
                height="150px"
                width="150px"
                contain
              />
            </template>
            <template v-slot:item.amount="{ item }">
              <v-text-field
                :value="item.amount"
                prepend-icon="mdi-minus"
                append-outer-icon="mdi-plus"
                readonly
                type="number"
                outlined
                dense
                hide-details
                class="number-pad"
                @click:append-outer="addToCart(item)"
                @click:prepend="removeFromCart(item)"
              >
              </v-text-field>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon color="error" @click="removeFromCart(item, true)">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
          <div class="text-right mt-2">商品點數: {{ point }} 點</div>
          <div class="text-right mt-2">運費點數: {{ deliveryFee }} 點</div>
          <div class="text-right mt-2 font-weight-bold">
            小計: {{ totalPoint }} 點
          </div>

          <v-divider class="my-4"></v-divider>
          <h3>收件資訊</h3>
          <v-text-field
            class="mt-2"
            label="寄件姓名 (必填)"
            v-model="form.name"
            hide-details
            dense
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="寄件電話 (必填)"
            v-model="form.phone"
            hide-details
            dense
            outlined
          ></v-text-field>
          <v-radio-group
            v-model="form.isSelfPickup"
            @change="form.address = ''"
            row
            hide-details
            class="mt-2 mb-4"
          >
            <v-radio label="店面自取" :value="true"></v-radio>
            <v-radio
              :label="`郵寄 (運費 200 點)，不提供寄送外島`"
              :value="false"
            ></v-radio>
          </v-radio-group>
          <v-select
            v-if="form.isSelfPickup"
            class="mt-2"
            label="店點 (必填)"
            v-model="form.address"
            :items="[
              { text: '請選擇', value: '', disabled: true },
              { text: '文賢店', value: '文賢店(自取)' },
              { text: '崇善店', value: '崇善店(自取)' },
              { text: '奇美店', value: '奇美店(自取)' },
            ]"
            hide-details
            dense
            outlined
          ></v-select>
          <v-text-field
            v-else
            class="mt-2"
            label="寄件地址 (必填)"
            v-model="form.address"
            hide-details
            dense
            outlined
          ></v-text-field>
          <div v-if="user.isVerified">
            <v-checkbox
              class="mt-2 ml-auto mr-0 fit-width"
              label="自動帶入會員資料"
              v-model="autofill"
              dense
              outlined
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="formDialog = false">取消</v-btn>
          <v-btn color="primary" :disabled="!verified" @click="confirmForm"
            >確認兌換</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "@/mixins/util";
import login from "@/mixins/login";

export default {
  name: "Product",
  mixins: [util, login],
  data: () => ({
    products: [],
    categoryList: [],
    category: "",
    keyword: "",
    formDialog: false,
    cart: [],
    form: {
      // product: {},
      // number: null,
      items: [],
      name: "",
      phone: "",
      address: "",
      userID: "",
      isSelfPickup: true,
    },
    headers: [
      { text: "商品", value: "image", sortable: false, align: "center" },
      { text: "", value: "商品名稱", sortable: false },
      { text: "點數", value: "點數", sortable: false },
      { text: "數量", value: "amount", sortable: false, align: "center" },
      { text: "移除", value: "action", sortable: false, align: "center" },
    ],
    previousTheme: false,
    menu: false,
  }),
  async created() {
    this.$vloading.show();
    const products = await this.getProducts("兌換商品", "文賢");
    this.products = products.filter((o) => o.線上兌換);
    this.categoryList = Array.from(
      new Set(this.products.filter((o) => o).map((o) => o.分類))
    ).map((o) => ({
      text: o,
      value: o,
    }));
    this.categoryList.unshift({ text: "全部", value: "" });
    this.category = "";
    this.$vloading.hide();
  },
  mounted() {
    this.previousTheme = this.$vuetify.theme.dark;
    this.$vuetify.theme.dark = true;
  },
  beforeDestroy() {
    this.$vuetify.theme.dark = this.previousTheme;
  },
  computed: {
    verified() {
      return (
        this.form.items.length > 0 &&
        this.form.name &&
        this.form.phone &&
        this.form.address &&
        this.user.balance >= this.totalPoint
      );
    },
    totalPoint() {
      if (!this.point) return 0;
      return this.point + this.deliveryFee;
    },
    point() {
      return this.form.items.reduce((total, product) => {
        return total + product.點數;
      }, 0);
    },
    deliveryFee() {
      if (this.form.isSelfPickup) {
        return 0;
      }
      return 200;
    },
    filterProducts() {
      let products = [...this.products];
      products = products.filter((o) => o.線上兌換);
      if (this.category) {
        products = products.filter((o) => o.分類.includes(this.category));
      }
      if (this.keyword) {
        products = products.filter((o) =>
          o.商品名稱.toUpperCase().includes(this.keyword?.toUpperCase())
        );
      }
      return products;
    },
    cartSum() {
      const group = _.groupBy(this.form.items, (product) => product.id);
      return Object.values(group).map((array) => {
        return { ...array[0], amount: array.length };
      });
    },
    autofill: {
      get() {
        return (
          this.form.name === this.user.name &&
          this.form.phone === this.user.phone &&
          this.form.address === this.user.address
        );
      },
      set(val) {
        if (val) {
          this.form.name = this.user.name;
          this.form.phone = this.user.phone;

          if (!this.form.isSelfPickup) {
            this.form.address = this.user.address;
          }
        }
      },
    },
  },
  methods: {
    addToCart(product, hasToast) {
      this.form.items.push(product);

      if (hasToast) {
        this.$toast.success("加入購物車");
      }
    },
    removeFromCart(product, all) {
      if (all) {
        this.form.items = this.form.items.filter(
          (item) => item.id !== product.id
        );
        return;
      }

      const index = this.form.items.findIndex((item) => item.id === product.id);
      if (index !== -1) {
        this.form.items.splice(index, 1);
      }
    },
    selectProduct(product) {
      this.form.product = product;
      this.formDialog = true;
    },
    resetForm() {
      this.formDialog = false;
      this.form = {
        // product: {},
        // number: null,
        items: [],
        name: "",
        phone: "",
        address: "",
        userID: "",
        isSelfPickup: true,
      };
    },
    async confirmForm() {
      if (confirm(`送出後不可取消，確認使用 ${this.totalPoint} 點兌換？`)) {
        try {
          this.$vloading.show();
          await this.axios.post(`/onlineTrade-multiple`, {
            // store: this.store,
            // token: this.token,
            // form: this.form,
            ...this.form,
            point: this.point,
            userID: this.userID,
          });
          await this.getUser();
          this.resetForm();
          this.$toast.success(`兌換完成！`);
        } catch (error) {
          console.log(error);
          this.$toast.error(`兌換失敗，請聯繫管理者！`);
        } finally {
          this.$vloading.hide();
        }
      }
    },
    navigateTo(name) {
      this.menu = false;
      this.$router.push({ name, query: { store: "@695yimoc" } });
      return;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;

  width: 100%;
  background-color: #fff;
  z-index: 10;
  //   border-bottom: 2px solid #0000004a;

  ::v-deep .v-toolbar__content {
    padding: 10px 20px;
  }
}

// .empty {
//   height: 70px;
// }
.item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

.item {
  display: flex;
  flex-direction: column;
  width: 160px;
  padding: 10px;
  margin: 5px;
  background-color: #d2e7fa1a;
  font-weight: 500;
  font-size: 14px;
}

.number-pad {
  width: 135px;
  margin: 0 auto;
}
</style>
