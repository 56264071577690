<script>
import liff from "@line/liff";
import Cookies from "js-cookie";

const liffIds = {
  dev: "2000443672-DgALwlqE",
  StageIchiban: "2000443672-eqgMWq3r",
  Ichiban: "2000443672-LgyQbx4M",
  Barcode: "2000443672-De6QL2Er",
  Qrcode: "2000443672-DyrXWpke",
  Product: "2000443672-lZn3O7mj",
  History: "2000443672-r3Vgx2Xm",
  BallHistory: "2000443672-xap3RXar",
  Owner: "2000443672-Zb8ADXam",
  StageOwner: "2000443672-jKZxkzw7",
};

export default {
  data: () => ({
    userID: null,
    UID: null,
    user: null,
    token: null,
    store: null,
  }),
  async mounted() {
    try {
      this.$vloading.show();
      let state = this.$route.query["liff.state"]
        ? decodeURIComponent(this.$route.query["liff.state"])
        : null;
      let store = this.$route.query.store || state?.replace("?store=", "");
      store = typeof store == "object" ? store[0] : store;

      await liff.init({ liffId: liffIds[this.$route.name] });
      if (liff.isLoggedIn()) {
        let profile = await liff.getProfile();
        let token = liff.getAccessToken();
        this.userID = `${store?.slice(-4)}${profile.userId.slice(-12)}`;
        this.UID = profile.userId;
        this.store = store;
        this.token = token;

        this.$store.commit("clientModule/setState", {
          userID: this.userID,
          UID: this.UID,
          store: this.store,
          token: this.token,
        });
        await this.getUser();
      } else {
        liff.login({ redirectUri: location.href });
      }

      const { data } = await this.axios.get("/version/latest");
      const latestVersion = data.timestamp;
      const storedVersion = Cookies.get("liff_version");
      // 如果最新版本號與儲存的版本號不同，重新載入頁面
      if (latestVersion !== storedVersion) {
        // 更新 cookie 中的版本號
        Cookies.set("liff_version", latestVersion);
        // hard reload
        window.location.reload();
      }
    } catch (error) {
      alert(error);
    } finally {
      this.$vloading.hide();
    }
  },
  methods: {
    go(path) {
      window.location.href = `https://liff.line.me/${liffIds[path]}?store=${this.store}`;
    },
    async getUser() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getUser`, payload);
        this.user = data;

        this.$store.commit("clientModule/setState", {
          user: this.user,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
  },
};
</script>
